// lib
import { createContext, useState } from "react";
// local
import axios from "../api/axios";
import { GetCookies } from '../cookies/Cookies.js';


const AboutContext = createContext();


export const AboutProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [contentAbout, setContentAbout] = useState('');
  const [contentPrivacy, setContentPrivacy] = useState('');
  const [contentTerms, setContentTerms] = useState('');

  const aboutUs = async () => {
    const token = JSON.parse(GetCookies('user')).token;
    try {
      setLoading(true);
      const res = await axios.get('/aboutUs', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (res.data) {
        setContentAbout(res.data.data.text);
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setLoading(false);
    }
  }

  const privacy = async () => {
    const token = JSON.parse(GetCookies('user')).token;
    try {
      setLoading(true);
      const res = await axios.get('/privacy', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (res.data) {
        setContentPrivacy(res.data.data.text);
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setLoading(false);
    }
  }
  const terms = async () => {
    const token = JSON.parse(GetCookies('user')).token;
    try {
      setLoading(true);
      const res = await axios.get('/term', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (res.data) {
        setContentTerms(res.data.data.text);
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setLoading(false);
    }
  }

  return (
    <AboutContext.Provider value={{
      aboutUs,
      privacy,
      terms,
      loading,
      contentAbout,
      contentPrivacy,
      contentTerms,
    }}>
      {children}
    </AboutContext.Provider>
  )
}

export default AboutContext;