// lib
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Swal from "sweetalert2";

// local
import HeaderSubMenu from "../../components/HeaderSubMenu";
import AuthContext from "../../context/AuthProvider";

import { GetCookies } from "../../cookies/Cookies";

const ForgotPassword = () => {
  // useNavigate
  const navigate = useNavigate();
  // Context
  const {
    loadingRequestForgotPassword,
    requestForgotPassword,
    setPageForgot,
    pageForgot,
    checkForgotPasswordCode,
    loadingPasswordCheck,

    passwordShowReset,
    rePasswordShowReset,
    showPasswordReset,
    showRePasswordReset,
    passwordResetFunc,
    loadingResetPassword,
  } = useContext(AuthContext);
  // useRef
  const emailRef = useRef();
  const codeRef = useRef();

  const codeResetRef = useRef();
  const passwordResetRef = useRef();
  const rePasswordResetRef = useRef();

  // useState
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const [codeReset, setCodeReset] = useState("");
  const [passwordReset, setPasswordReset] = useState("");
  const [rePasswordReset, setRePasswordReset] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    await requestForgotPassword({ email });
  };
  const handleSubmitCheck = async (e) => {
    e.preventDefault();
    await checkForgotPasswordCode({ kode: code });
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (passwordReset !== rePasswordReset) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Reset",
        text: "Password Not Match",
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }

    await passwordResetFunc({
      kode: codeReset,
      password: passwordReset,
      password_confirmation: rePasswordReset,
    });
  };
  useEffect(() => {
    if (GetCookies("user") !== undefined) {
      navigate("/");
    }
  }, [navigate]);
  useEffect(() => {
    setPageForgot("forgot");
  }, []);
  return (
    <>
      <HeaderSubMenu name={"Forgot Password"} />
      <div className="flex flex-col gap-y-5 mt-10">
        {pageForgot == "forgot" ? (
          <h1 className="ml-6 text-xl font-semibold">Input your email</h1>
        ) : pageForgot == "check" ? (
          <h1 className="ml-6 text-xl font-semibold">Input your code</h1>
        ) : pageForgot == "reset" ? (
          <h1 className="ml-6 text-xl font-semibold">Reset Your Password</h1>
        ) : null}

        {pageForgot == "forgot" ? (
          <form onSubmit={handleSubmit} className="mx-6">
            {/* Email */}
            <div className="flex flex-col gap-y-1">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Input your email"
                className="px-5 py-2 w-full border rounded-lg"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                ref={emailRef}
              />
            </div>
            <br />
            <button
              className={`h-12 rounded-xl text-white block w-full ${
                loadingRequestForgotPassword ? "bg-red-300" : "bg-red-500"
              }`}
              type="submit"
              disabled={loadingRequestForgotPassword ? true : false}
            >
              {loadingRequestForgotPassword ? (
                <FontAwesomeIcon
                  icon={solid("spinner")}
                  className="animate-spin text-white h-8 w-8"
                />
              ) : (
                "Request"
              )}
            </button>
          </form>
        ) : pageForgot == "check" ? (
          <form onSubmit={handleSubmitCheck} className="mx-6">
            {/* Code */}
            <div className="flex flex-col gap-y-1">
              <label htmlFor="codeCheck">Code</label>
              <input
                type="text"
                id="codeCheck"
                placeholder="Input your code"
                className="px-5 py-2 w-full border rounded-lg"
                autoComplete="off"
                onChange={(e) => setCode(e.target.value)}
                value={code}
                required
                ref={codeRef}
              />
            </div>
            <br />
            <button
              className={`h-12 rounded-xl text-white block w-full ${
                loadingPasswordCheck ? "bg-red-300" : "bg-red-500"
              }`}
              type="submit"
              disabled={loadingPasswordCheck ? true : false}
            >
              {loadingPasswordCheck ? (
                <FontAwesomeIcon
                  icon={solid("spinner")}
                  className="animate-spin text-white h-8 w-8"
                />
              ) : (
                "Send Code"
              )}
            </button>
          </form>
        ) : (
          // Buat useState, masukan functino ke provider
          <form onSubmit={handleResetPassword} className="mx-6">
            {/* Code */}
            <div className="flex flex-col gap-y-1">
              <label htmlFor="codeCheckReset">Code</label>
              <input
                type="text"
                id="codeCheckReset"
                placeholder="Input your code"
                className="px-5 py-2 w-full border rounded-lg"
                autoComplete="off"
                onChange={(e) => setCodeReset(e.target.value)}
                value={codeReset}
                required
                ref={codeResetRef}
              />
            </div>
            <br />
            {/* Password */}
            <div className="flex flex-col gap-y-1">
              <label htmlFor="passwordReset">New Password</label>
              <div className="flex">
                <input
                  type="password"
                  id="passwordReset"
                  placeholder="Input your new password"
                  className="pl-5 pr-10 py-2 w-full border rounded-lg"
                  autoComplete="off"
                  onChange={(e) => setPasswordReset(e.target.value)}
                  value={passwordReset}
                  required
                  ref={passwordResetRef}
                />
                <button
                  type="button"
                  className="-m-10"
                  onClick={(e) => {
                    showPasswordReset();
                    passwordShowReset
                      ? (passwordResetRef.current.type = "text")
                      : (passwordResetRef.current.type = "password");
                  }}
                >
                  {passwordShowReset ? (
                    <FontAwesomeIcon
                      icon={solid("eye-slash")}
                      className="h-6 w-6 text-gray-500"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={solid("eye")}
                      className="h-6 w-6 text-gray-500"
                    />
                  )}
                </button>
              </div>
            </div>
            <br />
            {/* Password Confirm */}
            <div className="flex flex-col gap-y-1">
              <label htmlFor="passwordResetConfirm">Confirm New Password</label>
              <div className="flex">
                <input
                  type="password"
                  id="passwordResetConfirm"
                  placeholder="Re enter new password"
                  className="pl-5 pr-10 py-2 w-full border rounded-lg"
                  autoComplete="off"
                  onChange={(e) => setRePasswordReset(e.target.value)}
                  value={rePasswordReset}
                  required
                  ref={rePasswordResetRef}
                />
                <button
                  type="button"
                  className="-m-10"
                  onClick={(e) => {
                    showRePasswordReset();
                    rePasswordShowReset
                      ? (rePasswordResetRef.current.type = "text")
                      : (rePasswordResetRef.current.type = "password");
                  }}
                >
                  {rePasswordShowReset ? (
                    <FontAwesomeIcon
                      icon={solid("eye-slash")}
                      className="h-6 w-6 text-gray-500"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={solid("eye")}
                      className="h-6 w-6 text-gray-500"
                    />
                  )}
                </button>
              </div>
            </div>
            <br />
            <button
              className={`h-12 rounded-xl text-white block w-full ${
                loadingResetPassword ? "bg-red-300" : "bg-red-500"
              }`}
              type="submit"
              disabled={loadingResetPassword ? true : false}
            >
              {loadingResetPassword ? (
                <FontAwesomeIcon
                  icon={solid("spinner")}
                  className="animate-spin text-white h-8 w-8"
                />
              ) : (
                "Reset Password"
              )}
            </button>
          </form>
        )}
      </div>
    </>
  );
};

export default ForgotPassword;
