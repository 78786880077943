import React from 'react';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from './pages/About';
import AboutUs from './pages/about/AboutUs';
import PrivacyPolicy from './pages/about/PrivacyPolicy';
import TermsOfService from './pages/about/TermsOfService';
import Auth from './pages/auth/Auth';
import ForgotPassword from './pages/auth/ForgotPassword';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import EditProfile from './pages/EditProfile';
// import Insights from './pages/Insights';
import Kailoka from './pages/Kailoka';
import Home from './pages/main/Home';
import Profile from './pages/main/Profile';
import ManageAccount from './pages/ManageAccount';
import MyQrCode from './pages/MyQrCode';
import SettingsAndPrivacy from './pages/SettingsAndPrivacy';
import Shop from './pages/Shop';

function App() {
  return (
    <div className='font-Poppins'>
      <Router>
        <Routes>
          {/* Auth */}
          <Route path='/auth' element={<Auth />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />

          {/* End Auth */}
          <Route exact path='/' element={<Home />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/edit-profile' element={<EditProfile />} />
          {/* Menu Profile */}
          <Route path='/profile/manage-account' element={<ManageAccount />} />
          {/* <Route path='/profile/insights' element={<Insights />} /> */}
          <Route path='/profile/my-qr-code' element={<MyQrCode />} />
          <Route path='/profile/kailoka' element={<Kailoka />} />
          <Route path='/profile/settings-and-privacy' element={<SettingsAndPrivacy />} />
          <Route path='/profile/about' element={<About />} />
          {/* In About */}
          <Route path='/profile/about/about-us' element={<AboutUs />} />
          <Route path='/profile/about/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/profile/about/terms-of-service' element={<TermsOfService />} />
          {/* End In About */}
          <Route path='/profile/shop' element={<Shop />} />

          {/* End Menu Profile */}
        </Routes>
      </Router>
    </div>

  );
}

export default App;
