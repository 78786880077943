// lib
import { createContext, useState } from "react";
import Swal from "sweetalert2";

// local
import axios from "../api/axios";
import { GetCookies } from '../cookies/Cookies.js';

const KailokaContext = createContext();

export const KailokaProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingQr, setLoadingQr] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);


  const [qr, setQr] = useState({});
  const [activeLink, setActiveLink] = useState({});


  const connectLink = async (data) => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;

    try {
      setLoading(true);
      const res = await axios.post(`/active/link/${uuid}`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (res.data) {
        Swal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          title: "Success Update Link",
          text: false,
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
        });
      }
      return true;
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Connect Link",
        text: err.response.data.message,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      return false;
    } finally {
      checkActiveLink();
      setLoading(false);
    }
  }

  const disconnectink = async () => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;

    try {
      setLoading(true);
      const res = await axios.get(`/disconnect/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (res.data) {
        Swal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          title: "Success Disconnect Link",
          text: false,
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
        });
      }
      return true;
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Disconnect Link",
        text: err.response.data.message,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      return false;
    } finally {
      checkActiveLink();
      setLoading(false);
    }
  }

  const checkActiveLink = async () => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;

    try {
      setLoadingActive(true);
      const { data } = await axios.get(`/active/link/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setActiveLink(data.data);
    } catch (err) {
      console.log(err)
    } finally {
      setLoadingActive(false);

    }
  }

  const getQrCode = async () => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;

    try {
      setLoadingQr(true);
      const { data } = await axios.get(`/qr_code/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setQr(data.data.qr_code);
    } catch (err) {
      console.log(err)
    } finally {
      setLoadingQr(false);
    }
  }
  return (
    <KailokaContext.Provider value={{
      loading,
      loadingQr,
      connectLink,
      disconnectink,
      qr,
      getQrCode,
      checkActiveLink,
      loadingActive,
      activeLink,
    }}>
      {children}
    </KailokaContext.Provider>
  )
}
export default KailokaContext;