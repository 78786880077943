// lib
import { createContext, useState } from "react";
import Swal from "sweetalert2";

// local
import axios from "../api/axios";
import { GetCookies } from '../cookies/Cookies.js';

const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const [account, setAccount] = useState({});

  const getAccount = async () => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;

    try {
      setLoading(true);
      const { data } = await axios.get(`/edit_account/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setAccount(data.data)
      return true
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setLoading(false);
    }
  }

  const updateAccount = async (data) => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;
    try {
      setLoadingEdit(true);
      const res = await axios.post(`/update_account/${uuid}`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (res.data) {
        Swal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          title: "Success Update Account",
          text: false,
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
        });
      }
      return true;
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Update Account",
        text: err.response.data.message,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      return false;
    } finally {
      setLoadingEdit(false);
    }
  }

  return (
    <AccountContext.Provider value={{ loading, getAccount, account, updateAccount, loadingEdit }}>
      {children}
    </AccountContext.Provider>
  )
}
export default AccountContext;