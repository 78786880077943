// Lib
import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// Local
import HeaderSubMenu from "../../components/HeaderSubMenu";
import AuthContext from "../../context/AuthProvider";
import { GetCookies } from "../../cookies/Cookies";

const Login = () => {
  // Context
  const {
    login,
    loading,
    showPassword,
    passwordShow,
    setPasswordShow,
    setRePasswordShow,
  } = useContext(AuthContext);
  // useRef
  const emailRef = useRef();
  const passwordRef = useRef();

  // useState
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // useNavigate
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await login({ email, password });
    if (result) {
      navigate("/");
    }
  };
  useEffect(() => {
    if (GetCookies("user") !== undefined) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setPasswordShow(true);
    setRePasswordShow(true);
    emailRef.current.focus();
  }, [setPasswordShow, setRePasswordShow]);

  return (
    <>
      <HeaderSubMenu name={"Sign In"} />
      <div className="flex flex-col gap-y-5 mt-10">
        <h1 className="ml-6 text-xl font-semibold">Glad to see you back!</h1>
        <form onSubmit={handleSubmit} className="mx-6">
          {/* Email */}
          <div className="flex flex-col gap-y-1">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Input your email"
              className="px-5 py-2 w-full border rounded-lg"
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              ref={emailRef}
            />
          </div>
          <br />
          {/* Password */}
          <div className="flex flex-col gap-y-1">
            <label htmlFor="password">Password</label>
            <div className="flex">
              <input
                type="password"
                id="password"
                placeholder="Input your password"
                className="pl-5 pr-10 py-2 w-full border rounded-lg"
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                ref={passwordRef}
              />
              <button
                type="button"
                className="-ml-7"
                onClick={(e) => {
                  showPassword();
                  passwordShow
                    ? (passwordRef.current.type = "text")
                    : (passwordRef.current.type = "password");
                }}
              >
                {passwordShow ? (
                  <FontAwesomeIcon
                    icon={solid("eye-slash")}
                    className="h-6 w-6 text-gray-500"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={solid("eye")}
                    className="h-6 w-6 text-gray-500"
                  />
                )}
              </button>
            </div>
          </div>

          <Link
            to={"/forgot-password"}
            className="ml-5 text-blue-400 inline-block w-36 my-4"
          >
            Forgot Password
          </Link>

          <button
            className={`h-12 rounded-xl text-white block w-full ${
              loading ? "bg-red-300" : "bg-red-500"
            }`}
            type="submit"
            disabled={loading ? true : false}
          >
            {loading ? (
              <FontAwesomeIcon
                icon={solid("spinner")}
                className="animate-spin text-white h-8 w-8"
              />
            ) : (
              "Sign In"
            )}
          </button>
        </form>
      </div>
    </>
  );
};

export default Login;
