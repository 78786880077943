// lib
import React, { useContext, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// local
import HeaderSubMenu from "../components/HeaderSubMenu";
import AccountContext from "../context/AccountProvider";
import { GetCookies } from "../cookies/Cookies";

const ManageAccount = () => {
  // useNavigate
  const navigate = useNavigate();
  // useContext
  const { loading, getAccount, account, updateAccount, loadingEdit } =
    useContext(AccountContext);
  // useRef
  const emailRef = useRef();
  const birthdayRef = useRef();
  const genderRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    const birthday = birthdayRef.current.value;
    const gender = genderRef.current.value;
    await updateAccount({ email, birthday, gender });
  };

  // useEffect
  useEffect(() => {
    if (GetCookies("user") === undefined) {
      navigate("/auth");
    }
  }, [navigate]);

  useEffect(() => {
    getAccount();
  }, []);
  return (
    <>
      <HeaderSubMenu name={"Manage Account"} />
      {/* Form */}
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="mt-6 gap-y-5 flex flex-col mx-6"
      >
        {/* Date */}
        {loading ? (
          <div className="mt-5">
            <Skeleton height={40} />
          </div>
        ) : (
          <div className="flex flex-col gap-y-1">
            <label htmlFor="birthday">Date of Birth</label>
            <input
              type="date"
              id="birthday"
              name="birthday"
              className="px-5 py-2 w-full border rounded-lg"
              defaultValue={account.birthday != null ? account.birthday : ""}
              required
              ref={birthdayRef}
            />
          </div>
        )}

        {/* Genders */}
        {loading ? (
          <div className="mt-5">
            <Skeleton height={40} />
          </div>
        ) : (
          <div className="flex flex-col gap-y-1">
            <label htmlFor="gender">Gender</label>
            <select
              name="gender"
              id="gender"
              className="px-5 py-2 w-full border rounded-lg"
              defaultValue={account.gender != null ? account.gender : ""}
              required
              ref={genderRef}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
        )}

        {/* Email */}
        {loading ? (
          <div className="mt-5">
            <Skeleton height={40} />
          </div>
        ) : (
          <div className="flex flex-col gap-y-1">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              className="px-5 py-2 w-full border rounded-lg"
              defaultValue={account.email != null ? account.email : ""}
              required
              ref={emailRef}
            />
          </div>
        )}

        {/* Button Save */}
        {loading ? (
          <div className="mt-5">
            <Skeleton height={40} />
          </div>
        ) : (
          <button
            className={`h-12 rounded-xl text-white block w-full ${
              loadingEdit ? "bg-red-300" : "bg-red-500"
            }`}
            type="submit"
            disabled={loadingEdit ? true : false}
          >
            {loadingEdit ? (
              <FontAwesomeIcon
                icon={solid("spinner")}
                className="animate-spin text-white h-8 w-8"
              />
            ) : (
              "Save Changes"
            )}
          </button>
        )}
      </form>
    </>
  );
};

export default ManageAccount;
