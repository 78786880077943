// lib
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// local
import HeaderSubMenu from "../components/HeaderSubMenu";
import KailokaContext from "../context/KailokaProvider";
import { GetCookies } from "../cookies/Cookies";

const MyQrCode = () => {
  // useNavigate
  const navigate = useNavigate();
  const { loadingQr, qr, getQrCode } = useContext(KailokaContext);
  // useEffect
  useEffect(() => {
    if (GetCookies("user") === undefined) {
      navigate("/auth");
    }
  }, []);
  useEffect(() => {
    getQrCode();
  }, []);
  return (
    <>
      <HeaderSubMenu name={"My QR Code"} />
      <div className="mt-14 text-center">
        {/* <h1 className="font-semibold text-xl">Salvatore Ramos Brennan</h1>
        <p className="text-gray-500 text-xs">
          app.official.id/salvatoreramosbrennan
        </p> */}
        {loadingQr ? (
          <Skeleton />
        ) : (
          <img
            src={`https://official.id/${qr}`}
            alt="qrcode"
            className="w-72 mx-auto mt-6"
          />
        )}

        {/* <div className="grid grid-cols-2 divide-x mx-6 mt-6">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 mx-auto text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
              />
            </svg>
            <p className="text-sm text-gray-500">Share QR</p>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 mx-auto text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
              />
            </svg>
            <p className="text-sm text-gray-500">Save as Image</p>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default MyQrCode;
