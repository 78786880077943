// lib
import React, { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useNavigate } from "react-router-dom";
// local
import BottomNavbar from "../../components/BottomNavbar";
import AuthContext from "../../context/AuthProvider";
import ProfileContext from "../../context/ProfileProvider";
import { GetCookies } from "../../cookies/Cookies";
import kailoka from "../../assets/images/logo/kailoka.png";
import background from "../../assets/images/background.png";

const Profile = () => {
  // useNavigate
  const navigate = useNavigate();
  // useContext
  const { logout } = useContext(AuthContext);
  const { loading, getProfilePage, profilePage } = useContext(ProfileContext);

  const handleLogout = async () => {
    const result = await logout();
    if (result) {
      navigate("/auth");
    }
  };
  // useEffect
  useEffect(() => {
    if (GetCookies("user") === undefined) {
      navigate("/auth");
    }
  }, [navigate]);

  useEffect(() => {
    getProfilePage();
  }, []);
  return (
    <>
      {/* Header Profile */}
      <div className="grid grid-cols-4 gap-4 mt-6 ">
        {loading ? (
          <div className="mx-auto">
            <Skeleton circle={true} height={80} width={80} />
          </div>
        ) : (
          <img
            src={
              profilePage.image == "" ||
              profilePage.image == null ||
              profilePage.image === "" ||
              profilePage.image === null
                ? kailoka
                : `https://official.id/${profilePage.image}`
            }
            alt="profileImage"
            className="w-20 h-20 rounded-full object-cover mx-auto border"
          />
        )}

        <div className="col-span-3 my-auto">
          {loading ? (
            <div className="mr-6">
              <Skeleton height={25} />
            </div>
          ) : (
            <h1 className="text-lg font-semibold">{profilePage.name}</h1>
          )}
          {loading ? (
            <div className="mr-6">
              <Skeleton height={20} />
            </div>
          ) : (
            <a
              href={`https://official.id/${profilePage.url}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-400 underline text-sm"
            >
              {`official.id/${profilePage.url}`}
            </a>
          )}
        </div>
      </div>
      {/* Image */}
      {loading ? (
        <div className="mt-2 mb-5">
          <Skeleton height={224} />
        </div>
      ) : (
        <img
          // src="https://images.unsplash.com/photo-1654327911816-da98acbb1564?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          src={
            profilePage.image_background == "" ||
            profilePage.image_background == null ||
            profilePage.image_background === "" ||
            profilePage.image_background === null
              ? background
              : `https://official.id/${profilePage.image_background}`
          }
          alt="imageCenter"
          className="mt-2 mb-5 shadow-inner shadow-white"
        />
      )}
      {/* Menu Profile*/}
      <div className="gap-y-2 flex flex-col">
        {/* Manage Account */}
        <Link to={"/profile/manage-account"} className="grid grid-cols-5 ">
          <div className="m-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-red-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </div>
          <div className="col-span-3 text-gray-600 my-auto">Manage Account</div>
          <div className="m-auto text-2xl text-gray-700">{">"}</div>
        </Link>
        <hr className="mx-2 mt-1" />
        {/* End Manage Account */}
        {/* Insights */}
        {/* <Link to={"/profile/insights"} className="grid grid-cols-5 ">
          <div className="m-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-red-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              />
            </svg>
          </div>
          <div className="col-span-3 text-gray-600 my-auto">Insights</div>
          <div className="m-auto text-2xl text-gray-700">{">"}</div>
        </Link>
        <hr className="mx-2 mt-1" /> */}
        {/* End Insights */}
        {/* My QR Code */}
        <Link to={"/profile/my-qr-code"} className="grid grid-cols-5 ">
          <div className="m-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-red-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
              />
            </svg>
          </div>
          <div className="col-span-3 text-gray-600 my-auto">My QR Code</div>
          <div className="m-auto text-2xl text-gray-700">{">"}</div>
        </Link>
        <hr className="mx-2 mt-1" />
        {/* End My QR Code */}
        {/* Kailoka */}
        <Link to={"/profile/kailoka"} className="grid grid-cols-5 ">
          <div className="m-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-red-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
              />
            </svg>
          </div>
          <div className="col-span-3 text-gray-600 my-auto">Kailoka</div>
          <div className="m-auto text-2xl text-gray-700">{">"}</div>
        </Link>
        <hr className="mx-2 mt-1" />
        {/* End Kailoka */}
        {/* Settings & Privacy */}
        {/* <Link
          to={"/profile/settings-and-privacy"}
          className="grid grid-cols-5 "
        >
          <div className="m-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-red-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <div className="col-span-3 text-gray-600 my-auto">
            Settings & Privacy
          </div>
          <div className="m-auto text-2xl text-gray-700">{">"}</div>
        </Link>
        <hr className="mx-2 mt-1" /> */}
        {/* End Settings & Privacy */}
        {/* About */}
        <Link to={"/profile/about"} className="grid grid-cols-5 ">
          <div className="m-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-red-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="col-span-3 text-gray-600 my-auto">About</div>
          <div className="m-auto text-2xl text-gray-700">{">"}</div>
        </Link>
        <hr className="mx-2 mt-1" />
        {/* End About */}
        {/* Shop */}
        <Link to={"/profile/shop"} className="grid grid-cols-5 ">
          <div className="m-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-red-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
          </div>
          <div className="col-span-3 text-gray-600 my-auto">Shop</div>
          <div className="m-auto text-2xl text-gray-700">{">"}</div>
        </Link>
        {/* End Shop */}
      </div>
      <button type="button" onClick={handleLogout}>
        <p className="text-red-500  ml-6 mt-6 mb-16 inline-block">Log Out</p>
      </button>

      <BottomNavbar />
    </>
  );
};

export default Profile;
