// lib
import { createContext, useState } from "react";
import Swal from "sweetalert2";

// local
import axios from "../api/axios";
import { GetCookies } from '../cookies/Cookies.js';
import facebook from "../assets/images/logo/facebook.png";
import instagram from "../assets/images/logo/instagram.png";
import line from "../assets/images/logo/line.png";
import shopee from "../assets/images/logo/shopee.png";
import telegram from "../assets/images/logo/telegram.png";
import tiktok from "../assets/images/logo/tiktok.png";
import tokopedia from "../assets/images/logo/tokopedia.png";
import twitter from "../assets/images/logo/twitter.png";
import whatsapp from "../assets/images/logo/whatsapp.png";
import kailoka from "../assets/images/logo/kailoka.png";


const HomeContext = createContext();


export const HomeProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [user, setUser] = useState({});
  const [availableSosmed, setAvailavleSosmed] = useState([]);
  const [notAvailableSosmed, setNotAvailableSosmed] = useState([]);
  const [addMore, setAddMore] = useState(false);

  const [nameSosmed, setNameSosmed] = useState('');
  const [linkSosmed, setLinkSosmed] = useState('');

  const [modalAdd, setModalAdd] = useState(false);
  const [modalRemove, setModalRemove] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  const [templateLink, setTemplateLink] = useState("");

  const getHome = async () => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;
    try {
      setLoading(true);
      setAvailavleSosmed([]);
      setNotAvailableSosmed([]);
      const { data } = await axios.get(`/home/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUser(data.data)
      for (const key in data.data.social_media) {
        if (data.data.social_media[key].link === "") {
          setNotAvailableSosmed(e => [...e, data.data.social_media[key]]);
        } else {
          setAvailavleSosmed(e => [...e, data.data.social_media[key]]);
        }
      }
      return true;
    } catch (err) {
      console.log(err)
      return false;
    } finally {
      setLoading(false);
    }
  }
  const showAddMore = () => {
    setAddMore(!addMore);
  }
  const addMoreSosmed = async (name) => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;
    try {
      setLoadingModal(true);
      const { data } = await axios.post(`/profile/socialmedia/${uuid}`, name, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: `Sukses Add ${data.data}`,
        text: false,
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
      setModalAdd(false);
      setAddMore(false);
      getHome();
      return true
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Add Sosmed",
        text: err.data,
        icon: "error",
        timer: 3000,
        timerProgressBar: true,
      });
      return false;
    } finally {
      setLoadingModal(false);
    }
  }
  const editSosmed = async (name) => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;
    try {
      setLoadingModal(true);
      const { data } = await axios.post(`/profile/socialmedia/${uuid}`, name, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: `Sukses Edit ${data.data}`,
        text: false,
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
      setModalEdit(false);
      setAddMore(false);
      getHome();
      return true
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Edit Sosmed",
        text: err.data,
        icon: "error",
        timer: 3000,
        timerProgressBar: true,
      });
      return false;
    } finally {
      setLoadingModal(false);
    }
  }
  const removeSosmed = async (name) => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;
    try {
      setLoadingModal(true)
      await axios.delete(`/profile/socialmedia/${uuid}/${name}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: `Sukses unlink`,
        text: false,
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
      setModalRemove(false);
      setAddMore(false);
      getHome();
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Unlink",
        text: err,
        icon: "error",
        timer: 3000,
        timerProgressBar: true,
      });
    } finally {
      setLoadingModal(false)
    }
  }
  const handleImage = (name) => {
    switch (name) {
      case "facebook":
        return facebook;
      case "instagram":
        return instagram;
      case "line":
        return line;
      case "shopee":
        return shopee;
      case "telegram":
        return telegram;
      case "tiktok":
        return tiktok;
      case "tokopedia":
        return tokopedia;
      case "twitter":
        return twitter;
      case "whatsapp":
        return whatsapp;
      default:
        return kailoka;
    }
  };

  const changeTemplateLink = async (name) => {
    switch (name) {
      case "facebook":
        // return setTemplateLink('https://id-id.facebook.com/qorygore');
        return setTemplateLink('https://id-id.facebook.com/');
      case "instagram":
        // return setTemplateLink('https://www.instagram.com/mfikriab');
        return setTemplateLink('https://www.instagram.com/');
      case "line":
        // return setTemplateLink('https://line.me/ti/p/trisna_yudhaa');
        return setTemplateLink('https://line.me/ti/p/');
      case "shopee":
        // return setTemplateLink('https://shopee.co.id/kulafa_clothing');
        return setTemplateLink('https://shopee.co.id/');
      case "telegram":
        // return setTemplateLink('https://t.me/m_fikri_ab');
        return setTemplateLink('https://t.me/');
      case "tiktok":
        // return setTemplateLink('https://www.tiktok.com/@towerbels');
        return setTemplateLink('https://www.tiktok.com/');
      case "tokopedia":
        // return setTemplateLink('https://www.tokopedia.com/kulafa');
        return setTemplateLink('https://www.tokopedia.com/');
      case "twitter":
        // return setTemplateLink('https://twitter.com/qorygore');
        return setTemplateLink('https://twitter.com/');
      case "whatsapp":
        // return setTemplateLink('https://wa.me/62');
        return setTemplateLink('https://wa.me/62');
      default:
        return setTemplateLink('https://');
    }
  }



  return (
    <HomeContext.Provider value={{
      templateLink,
      changeTemplateLink,
      loading,
      user,
      getHome,
      showAddMore,
      modalAdd,
      setModalAdd,
      addMore,
      availableSosmed,
      notAvailableSosmed,
      addMoreSosmed,
      nameSosmed,
      setNameSosmed,
      handleImage,
      modalRemove,
      setModalRemove,
      modalEdit,
      setModalEdit,
      removeSosmed,
      loadingModal,
      setLoadingModal,
      editSosmed,

      linkSosmed,
      setLinkSosmed,
    }}>
      {children}
    </HomeContext.Provider>

  )
}

export default HomeContext;