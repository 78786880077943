// lib
import React, { useEffect, useContext } from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
// local
import HeaderSubMenu from "../../components/HeaderSubMenu";
import AboutContext from "../../context/AboutProvider";

const TermsOfService = () => {
  // context
  const { terms, contentTerms, loading } = useContext(AboutContext);
  useEffect(() => {
    terms();
  }, []);

  return (
    <>
      <HeaderSubMenu name={"Terms Of Service"} />
      {loading ? (
        <div className="mx-6">
          <Skeleton height={30} />
        </div>
      ) : (
        <p className="mx-6 mt-6 text-justify">{contentTerms}</p>
      )}
    </>
  );
};

export default TermsOfService;
