import React from "react";
import HeaderSubMenu from "../components/HeaderSubMenu";
import kailoka from "../assets/images/logo/kailoka.png";
import shopee from "../assets/images/logo/shopee.png";

const Shop = () => {
  return (
    <>
      <HeaderSubMenu name={"Shop"} />
      {/* Logo */}
      <div className="text-center mt-10">
        <img src={kailoka} alt="kailoka" className="w-72 mx-auto" />
      </div>
      {/* Button shop */}
      <div className="flex flex-col mx-10 mt-10 gap-y-5">
        <a
          href="https://shopee.co.id/kailoka.official"
          className="w-full h-11 border rounded-xl border-gray-500 flex justify-center items-center gap-x-3"
        >
          <img src={shopee} alt="shopee" className="w-5" />
          Buy on Shopee
        </a>
      </div>
    </>
  );
};

export default Shop;
