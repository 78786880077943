// lib
import React, { useContext, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// local
import HomeContext from "../context/HomeProvider";

const ModalAddMore = () => {
  // useState
  const [link, setLink] = useState("");
  // useRef
  const linkRef = useRef();
  // useContext
  const {
    addMoreSosmed,
    nameSosmed,
    modalAdd,
    setModalAdd,
    handleImage,
    loadingModal,
    templateLink,
  } = useContext(HomeContext);

  // other
  const handleSubmit = async (e) => {
    e.preventDefault();
    await addMoreSosmed({ name: nameSosmed, link: templateLink + link });
    setLink("");
  };

  return (
    <div
      className={`bg-black bg-opacity-50 absolute inset-0 justify-center items-center z-50 overflow-auto ${
        modalAdd ? "flex" : "hidden"
      }`}
      onWheel={() => {
        setLink("");
        setModalAdd(false);
      }}
    >
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="bg-white rounded-xl w-11/12 py-10 flex flex-col justify-center"
      >
        <h1 className="text-center font-bold text-3xl mt-1 capitalize">
          {nameSosmed}
        </h1>
        <div className="flex justify-between my-5">
          <div className="w-1/4 flex justify-center">
            <img
              src={handleImage(nameSosmed)}
              alt={nameSosmed}
              className="w-20 h-20"
            />
          </div>
          <div className="flex flex-col gap-y-1 w-3/4 pr-1">
            <label htmlFor="textAdd" className="text-gray-600 capitalize">
              {nameSosmed}
            </label>
            <div className="flex items-center overflow-hidden border border-gray-400 rounded-lg pl-1">
              <span className="text-xs text-gray-500">{templateLink}</span>
              <input
                type="text"
                id="textAdd"
                placeholder="Input here"
                className="px-2 py-2 w-[95%] flex-grow border-0 outline-0 pl-1 placeholder:text-xs"
                autoComplete="off"
                onChange={(e) => setLink(e.target.value)}
                value={link}
                ref={linkRef}
                required
              />
            </div>
          </div>
        </div>
        <div className="flex justify-around items-center">
          <button
            type="button"
            className={`h-14 shadow-md  w-1/3 rounded-lg ${
              loadingModal ? "text-gray-400" : ""
            }`}
            onClick={() => setModalAdd(false)}
            disabled={loadingModal ? true : false}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`h-14 shadow-md w-1/3 rounded-lg text-white ${
              loadingModal ? "bg-red-300" : "bg-red-500"
            }`}
            disabled={loadingModal ? true : false}
          >
            {loadingModal ? (
              <FontAwesomeIcon
                icon={solid("spinner")}
                className="animate-spin text-white h-8 w-8"
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ModalAddMore;
