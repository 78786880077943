// lib
import React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// local
import kailoka from "../../assets/images/logo/kailoka.png";
import { GetCookies } from "../../cookies/Cookies";

const Auth = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (GetCookies("user") !== undefined) {
      navigate("/");
    }
  }, [navigate]);
  return (
    <div className="">
      <img src={kailoka} alt="kailoka" className="w-4/5 mx-auto mt-32" />

      <div className="grid grid-cols-1 absolute bottom-10 w-full gap-y-3">
        <Link
          to={"/login"}
          className="bg-red-500 mx-10 py-2 rounded-xl text-white border border-red-500 text-center"
        >
          Sign In
        </Link>
        <Link
          to={"/register"}
          className="mx-10 py-2 rounded-xl text-red-500 border border-red-500 text-center"
        >
          Create Account
        </Link>
      </div>
    </div>
  );
};

export default Auth;
