import Cookies from "js-cookie";


const SetCookies = (name, value) => {
  Cookies.set(name, value, {
    expires: 1,
    secure: true,
  });
}

const GetCookies = (name) => {
  return Cookies.get(name);
}

const RemoveCookies = (name) => {
  return Cookies.remove(name);
}

export { SetCookies, GetCookies, RemoveCookies };