// lib
import { createContext, useState } from "react";
import Swal from "sweetalert2";
import { SetCookies, RemoveCookies } from '../cookies/Cookies.js';
// local
import axios from "../api/axios";
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({}); // User Data
  const [loading, setLoading] = useState(false); // Loading if click Button
  const [passwordShow, setPasswordShow] = useState(true); // For Show Password
  const [rePasswordShow, setRePasswordShow] = useState(true); // For Show Password

  const [pageForgot, setPageForgot] = useState('forgot');

  const [loadingRequestForgotPassword, setLoadingRequestForgotPassword] = useState(false);
  const [loadingPasswordCheck, setLoadingPasswordCheck] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);

  const [passwordShowReset, setPasswordShowReset] = useState(true); // For Show Password
  const [rePasswordShowReset, setRePasswordShowReset] = useState(true); // For Show Password

  const login = async (userData) => {
    try {
      setLoading(true);
      const res = await axios.post('/login', userData);

      if (res.data) {
        // Remove & Set New Cookies
        RemoveCookies('user');
        SetCookies('user', JSON.stringify(res.data.data));
        setUser(res.data.data);

        Swal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          title: "Success Login",
          text: false,
          icon: "success",
          timer: 3000,
          timerProgressBar: true,
        });
        return true;
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Login",
        text: err.response.data.message,
        icon: "error",
        timer: 3000,
        timerProgressBar: true,
      });
      return false;
    } finally {
      setLoading(false);
      setPasswordShow(true);
      setRePasswordShow(true);
    }
  }

  const showPassword = () => {
    setPasswordShow(!passwordShow);
  }
  const showRePassword = () => {
    setRePasswordShow(!rePasswordShow);
  }
  const showPasswordReset = () => {
    setPasswordShowReset(!passwordShowReset);
  }
  const showRePasswordReset = () => {
    setRePasswordShowReset(!passwordShowReset);
  }

  const logout = async () => {
    const swall = await Swal.fire({
      position: "center",
      showCancelButton: true,
      title: "Logout",
      text: 'Are you sure log out?',
      icon: "warning",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Log Out'
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveCookies('user');
        setUser({});
        Swal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          title: "Success Logout",
          text: false,
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
        });
        return true
      } else {
        return false
      }
    });
    return swall;
  }

  const register = async (userData) => {
    try {
      setLoading(true);
      const res = await axios.post('/registrasi', userData);

      if (res.data) {
        // Remove & Set New Cookies
        RemoveCookies('user');
        SetCookies('user', JSON.stringify(res.data.data));
        setUser(res.data.data);

        Swal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          title: "Success Register",
          text: false,
          icon: "success",
          timer: 3000,
          timerProgressBar: true,
        });
        return true;
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Register",
        text: err.response.data.message,
        icon: "error",
        timer: 3000,
        timerProgressBar: true,
      });
      return false;
    } finally {
      setLoading(false);
      setPasswordShow(true);
      setRePasswordShow(true);
    }
  }

  const requestForgotPassword = async (email) => {
    try {
      setLoadingRequestForgotPassword(true);
      const { data } = await axios.post('/password/forgot', email);

      if (data) {

        Swal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          title: "Success Request Check Email or Spam",
          text: data.message,
          icon: "success",
          timer: 3000,
          timerProgressBar: true,
        });
        setPageForgot('check');
        return true;
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Request",
        // text: err.response.data.message,
        text: "Try valid email",
        icon: "error",
        timer: 3000,
        timerProgressBar: true,
      });
      return false;
    } finally {
      setLoadingRequestForgotPassword(false);
    }
  }

  const checkForgotPasswordCode = async (code) => {
    try {
      setLoadingPasswordCheck(true);
      const { data } = await axios.post('/password/code/check', code);

      if (data.status) {
        Swal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          title: "Success Check Code",
          text: data.message,
          icon: "success",
          timer: 3000,
          timerProgressBar: true,
        });
        setPageForgot('reset');
        return true;
      } else {
        Swal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          title: "Failed Request Check Code",
          text: data.message,
          icon: "error",
          timer: 3000,
          timerProgressBar: true,
        });
        return false;
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Request Check Code",
        text: err,
        icon: "error",
        timer: 3000,
        timerProgressBar: true,
      });
      return false;
    } finally {
      setLoadingPasswordCheck(false);
    }
  }

  const passwordResetFunc = async (reset) => {
    try {
      setLoadingResetPassword(true);
      const { data } = await axios.post('/password/reset', reset);
      if (data.status) {
        Swal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          title: "Success Reset Password",
          text: data.message,
          icon: "success",
          timer: 3000,
          timerProgressBar: true,
        });
        setPageForgot('forgot');
        return true;
      } else {
        Swal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          title: "Failed Reset password",
          text: data.message,
          icon: "error",
          timer: 3000,
          timerProgressBar: true,
        });
        return false;
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Reset password",
        text: err,
        icon: "error",
        timer: 3000,
        timerProgressBar: true,
      });
      return false;
    } finally {
      setLoadingResetPassword(false);
    }
  }

  return (
    <AuthContext.Provider value={{
      login,
      register,
      logout,
      showPassword,
      showRePassword,
      setPasswordShow,
      setRePasswordShow,
      loading,
      user,
      passwordShow,
      rePasswordShow,

      loadingRequestForgotPassword,
      requestForgotPassword,
      setPageForgot,
      pageForgot,
      loadingPasswordCheck,
      checkForgotPasswordCode,

      setPasswordShowReset,
      setRePasswordShowReset,
      passwordShowReset,
      rePasswordShowReset,
      showPasswordReset,
      showRePasswordReset,
      passwordResetFunc,
      loadingResetPassword,
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;