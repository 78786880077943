import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Context
import { AuthProvider } from './context/AuthProvider';
import { AboutProvider } from './context/AboutProvider';
import { HomeProvider } from './context/HomeProvider';
import { ProfileProvider } from './context/ProfileProvider';
import { AccountProvider } from './context/AccountProvider';
import { KailokaProvider } from './context/KailokaProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <HomeProvider>
      <AboutProvider>
        <ProfileProvider>
          <AccountProvider>
            <KailokaProvider>
              <App />
            </KailokaProvider>
          </AccountProvider>
        </ProfileProvider>
      </AboutProvider>
    </HomeProvider>
  </AuthProvider>
);
