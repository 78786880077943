// lib
import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Swal from "sweetalert2";
// local
import HeaderSubMenu from "../../components/HeaderSubMenu";
import AuthContext from "../../context/AuthProvider";
import { GetCookies } from "../../cookies/Cookies";

const Register = () => {
  // context
  const {
    register,
    loading,
    showPassword,
    showRePassword,
    passwordShow,
    rePasswordShow,
    setPasswordShow,
    setRePasswordShow,
  } = useContext(AuthContext);
  // useRef
  const emailRef = useRef();
  const passwordRef = useRef();
  const rePasswordRef = useRef();
  // useState
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  // useNavigate
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    // Check password & Re password must match
    if (password !== rePassword) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Register",
        text: "Password Not Match",
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }
    const result = await register({ email, password });
    if (result) {
      navigate("/");
    }
  };
  // useEffect
  useEffect(() => {
    if (GetCookies("user") !== undefined) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setPasswordShow(true);
    setRePasswordShow(true);
    emailRef.current.focus();
  }, [setPasswordShow, setRePasswordShow]);
  return (
    <>
      <HeaderSubMenu name={"Create Account"} />
      <div className="flex flex-col gap-y-5 mt-10">
        <h1 className="ml-6 text-xl font-semibold">
          Welcome to Kailoka Kala NFC!
        </h1>
        <form onSubmit={handleRegister} className="mx-6">
          {/* Email */}
          <div className="flex flex-col gap-y-1">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Input yout email"
              className="px-5 py-2 w-full border rounded-lg"
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              ref={emailRef}
            />
          </div>
          <br />
          {/* Password */}
          <div className="flex flex-col gap-y-1">
            <label htmlFor="password">Password</label>
            <div className="flex">
              <input
                type="password"
                id="password"
                placeholder="Input your password"
                className="pl-5 pr-10 py-2 w-full border rounded-lg"
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                ref={passwordRef}
              />
              <button
                type="button"
                className="-ml-7"
                onClick={(e) => {
                  showPassword();
                  passwordShow
                    ? (passwordRef.current.type = "text")
                    : (passwordRef.current.type = "password");
                }}
              >
                {passwordShow ? (
                  <FontAwesomeIcon
                    icon={solid("eye-slash")}
                    className="h-6 w-6 text-gray-500"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={solid("eye")}
                    className="h-6 w-6 text-gray-500"
                  />
                )}
              </button>
            </div>
          </div>
          <br />
          {/* Re Enter Password */}
          <div className="flex flex-col gap-y-1">
            <label htmlFor="reenterpassword">Re-enter Password</label>
            <div className="flex">
              <input
                type="password"
                id="reenterpassword"
                placeholder="Input your password"
                className="pl-5 pr-10 py-2 w-full border rounded-lg"
                autoComplete="off"
                onChange={(e) => setRePassword(e.target.value)}
                value={rePassword}
                required
                ref={rePasswordRef}
              />
              <button
                type="button"
                className="-ml-7"
                onClick={(e) => {
                  showRePassword();
                  rePasswordShow
                    ? (rePasswordRef.current.type = "text")
                    : (rePasswordRef.current.type = "password");
                }}
              >
                {rePasswordShow ? (
                  <FontAwesomeIcon
                    icon={solid("eye-slash")}
                    className="h-6 w-6 text-gray-500"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={solid("eye")}
                    className="h-6 w-6 text-gray-500"
                  />
                )}
              </button>
            </div>
          </div>
          <br />
          <button
            className={`mt-2 h-12 rounded-xl text-white block w-full ${
              loading ? "bg-red-300" : "bg-red-500"
            }`}
            type="submit"
            disabled={loading ? true : false}
          >
            {loading ? (
              <FontAwesomeIcon
                icon={solid("spinner")}
                className="animate-spin text-white h-8 w-8"
              />
            ) : (
              "Create Account"
            )}
          </button>
        </form>
      </div>
    </>
  );
};

export default Register;
