import React from "react";
import HeaderSubMenu from "../components/HeaderSubMenu";

const SettingsAndPrivacy = () => {
  return (
    <>
      <HeaderSubMenu name={"Settings & Privacy"} />
    </>
  );
};

export default SettingsAndPrivacy;
