import React from "react";
import { Link } from "react-router-dom";
import HeaderSubMenu from "../components/HeaderSubMenu";

const About = () => {
  return (
    <>
      <HeaderSubMenu name={"About"} />
      <div className="mt-10 gap-y-2 flex flex-col">
        <Link to={"/profile/about/about-us"} className="grid grid-cols-4 ">
          <div className="col-span-3 my-auto ml-10">About US</div>
          <div className="m-auto text-2xl">{">"}</div>
        </Link>
        <hr className="mx-2 mt-1" />
        <Link
          to={"/profile/about/privacy-policy"}
          className="grid grid-cols-4 "
        >
          <div className="col-span-3 my-auto ml-10">Privacy Policy</div>
          <div className="m-auto text-2xl">{">"}</div>
        </Link>
        <hr className="mx-2 mt-1" />
        <Link
          to={"/profile/about/terms-of-service"}
          className="grid grid-cols-4 "
        >
          <div className="col-span-3 my-auto ml-10">Terms of Service</div>
          <div className="m-auto text-2xl">{">"}</div>
        </Link>
      </div>
    </>
  );
};

export default About;
