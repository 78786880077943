// lib
import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// local
import HomeContext from "../context/HomeProvider";

const ModalRemoveSosmed = () => {
  // useContext
  const {
    removeSosmed,
    nameSosmed,
    modalRemove,
    setModalRemove,
    loadingModal,
  } = useContext(HomeContext);

  // other
  const handleSubmit = async (e) => {
    e.preventDefault();
    await removeSosmed(nameSosmed);
  };
  return (
    <div
      className={`bg-black bg-opacity-50 absolute inset-0 justify-center items-center z-50 ${
        modalRemove ? "flex" : "hidden"
      }`}
    >
      <div className="bg-white rounded-xl w-11/12 py-10 flex flex-col justify-center">
        <h1 className="text-center font-bold text-2xl mt-3 capitalize">
          Remove Link
        </h1>
        <p className="text-center my-6 text-lg px-1">
          Are you sure want to unlink{" "}
          <span className="font-semibold text-red-500">{nameSosmed}</span> from
          your account?
        </p>
        <div className="flex justify-around items-center">
          <button
            type="button"
            className={`h-14 shadow-md  w-1/3 rounded-lg ${
              loadingModal ? "text-gray-400" : ""
            }`}
            onClick={() => setModalRemove(false)}
            disabled={loadingModal ? true : false}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`h-14 shadow-md w-1/3 rounded-lg text-white ${
              loadingModal ? "bg-red-300" : "bg-red-500"
            }`}
            disabled={loadingModal ? true : false}
            onClick={(e) => handleSubmit(e)}
          >
            {loadingModal ? (
              <FontAwesomeIcon
                icon={solid("spinner")}
                className="animate-spin text-white h-8 w-8"
              />
            ) : (
              "Yes"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalRemoveSosmed;
