// lib
import React, { useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// local
import BottomNavbar from "../../components/BottomNavbar";
import { GetCookies } from "../../cookies/Cookies";

import ModalAddMore from "../../components/ModalAddMore";

import HomeContext from "../../context/HomeProvider";
import ModalRemoveSosmed from "../../components/ModalRemoveSosmed";

import kailoka from "../../assets/images/logo/kailoka.png";
import background from "../../assets/images/background.png";
import ModalEditSosmed from "../../components/ModalEditSosmed";

function Home() {
  const {
    loading,
    getHome,
    user,
    availableSosmed,
    notAvailableSosmed,
    addMore,
    showAddMore,
    setNameSosmed,
    setModalAdd,
    handleImage,
    setModalRemove,
    modalAdd,
    modalRemove,
    modalEdit,
    setModalEdit,
    linkSosmed,
    setLinkSosmed,
    changeTemplateLink,
  } = useContext(HomeContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (GetCookies("user") === undefined) {
      navigate("/auth");
    }
  }, [navigate]);

  useEffect(() => {
    getHome();
  }, []);
  return (
    <div id="homePage">
      {/* Header */}
      <div className="relative">
        {loading ? (
          <Skeleton height={224} />
        ) : user.image_background === "" ||
          user.image_background == "" ||
          user.image_background === null ||
          user.image_background == null ? (
          <img
            src={background}
            className="w-full h-56 object-cover"
            alt="backgroundImg"
          />
        ) : (
          <img
            src={`https://official.id/${user.image_background}`}
            className="w-full h-56 object-cover"
            alt="backgroundImg"
          />
        )}
        {loading ? (
          <div className="absolute -bottom-12 left-0 right-0 flex justify-center">
            <Skeleton
              height={96}
              width={96}
              circle={true}
              className="border-4 border-white"
            />
          </div>
        ) : user.image === "" ||
          user.image == "" ||
          user.image === null ||
          user.image == null ? (
          <img
            src={kailoka}
            alt="profileImage"
            className="rounded-full w-24 h-24 object-cover absolute -bottom-12 left-0 right-0 mx-auto  border-4 border-white bg-slate-400"
          />
        ) : (
          <img
            src={`https://official.id/${user.image}`}
            alt="profileImage"
            className="rounded-full w-24 h-24 object-cover absolute -bottom-12 left-0 right-0 mx-auto  border-4 border-white bg-slate-400"
          />
        )}
      </div>
      {/* End Header */}
      {/* Profile */}
      <div className="mt-16 flex justify-center flex-col items-center">
        <h1 className="text-xl text-center mx-10">
          {loading ? <Skeleton height={30} width={100} /> : user.name}
        </h1>

        <p className="mx-8 mt-5 text-slate-500 text-center">
          {loading ? <Skeleton height={30} width={200} /> : user.bio}
        </p>
        {/* Edit Profile & Activate Link */}
        {loading ? (
          <div className="w-full mt-1 px-6">
            <Skeleton height={40} />
          </div>
        ) : (
          <div className="flex gap-x-3 mt-4 text-gray-600">
            <Link
              to="/edit-profile"
              className="border border-gray-400 rounded-xl py-1.5 px-9 font-medium"
            >
              Edit Profile
            </Link>
            <a
              target="_blank"
              rel="noreferrer"
              href={user.link}
              className="border border-gray-400 rounded-xl py-1.5 px-9 font-medium"
            >
              View Link
            </a>
          </div>
        )}
        {/* End Edit Profile & Activate Link */}
      </div>
      {/* End Profile */}
      {/* Container sosmed */}
      <div className="grid grid-cols-3 gap-4 mt-10 mx-8 mb-16">
        {/* Sosmed */}
        {loading ? (
          <>
            <div className="mx-auto flex flex-col items-center cursor-pointer">
              <Skeleton width={80} height={80} circle={true} />
              <Skeleton width={80} height={10} />
            </div>
            <div className="mx-auto flex flex-col items-center cursor-pointer">
              <Skeleton width={80} height={80} circle={true} />
              <Skeleton width={80} height={10} />
            </div>
            <div className="mx-auto flex flex-col items-center cursor-pointer">
              <Skeleton width={80} height={80} circle={true} />
              <Skeleton width={80} height={10} />
            </div>
          </>
        ) : (
          availableSosmed.map((e, i) => (
            <div
              className="mx-auto flex flex-col items-center cursor-pointer relative"
              key={i}
              onClick={() => {
                if (!addMore) {
                  setNameSosmed(e.name);
                  setLinkSosmed(e.link);
                  changeTemplateLink(e.name);
                  setModalEdit(true);
                }
              }}
            >
              {addMore ? (
                <a
                  onClick={() => {
                    setNameSosmed(e.name);
                    setModalRemove(true);
                  }}
                  href="#homePage"
                  className="h-5 w-5 absolute -top-2 -right-2 bg-red-500 z-40 rounded-full text-white font-bold text-center"
                >
                  -
                </a>
              ) : null}
              <img src={handleImage(e.name)} alt={e.name} width={80} />
              <p className="mt-2 capitalize">{e.name}</p>
            </div>
          ))
        )}

        {loading ? null : (
          <button
            className="mx-auto flex flex-col justify-between items-center"
            onClick={() => showAddMore()}
          >
            {addMore ? (
              <FontAwesomeIcon
                icon={solid("circle-xmark")}
                className="h-16 w-16 mt-3 text-red-500"
              />
            ) : (
              <FontAwesomeIcon
                icon={solid("circle-plus")}
                className="h-16 w-16 mt-3"
              />
            )}
            <p className="text-gray-400">
              {addMore ? "Close Add" : "Add more"}
            </p>
          </button>
        )}
      </div>
      {addMore ? <hr className="bg-gray-400 mx-6" /> : null}
      {addMore ? (
        <div className="grid grid-cols-3 gap-4 mt-10 mx-8 mb-16">
          {notAvailableSosmed.map((e, i) => (
            <a
              onClick={() => {
                setNameSosmed(e.name);
                changeTemplateLink(e.name);
                setModalAdd(true);
              }}
              href="#homePage"
              className="mx-auto flex flex-col items-center cursor-pointer"
              key={i}
            >
              <img src={handleImage(e.name)} alt={e.name} width={80} />
              <p className="mt-2 capitalize">{e.name}</p>
            </a>
          ))}
        </div>
      ) : null}
      {modalAdd ? <ModalAddMore /> : null}
      {modalRemove ? <ModalRemoveSosmed /> : null}
      {modalEdit ? <ModalEditSosmed /> : null}
      <BottomNavbar />
    </div>
  );
}

export default Home;
