import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const BottomNavbar = () => {
  const [home, setHome] = useState(true);
  const [profile, setProfile] = useState(false);

  // Class css
  let activeMenu =
    "rounded-lg bg-red-500 p-1 text-white border-b-2 border-yellow-500";
  let nonActiveMeu = "text-red-500";

  useEffect(() => {
    if (window.location.pathname === "/profile") {
      setHome(false);
      setProfile(true);
    } else if (
      window.location.pathname === "/" ||
      window.location.pathname === ""
    ) {
      setHome(true);
      setProfile(false);
    }
  }, []);

  return (
    <div className="fixed bottom-0 flex h-16 w-full items-center justify-around bg-white border-t-2 border-gray-200">
      <div className={`${home ? activeMenu : nonActiveMeu}`}>
        <Link to={"/"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-7 w-7"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
          </svg>
        </Link>
      </div>
      <div className={`${profile ? activeMenu : nonActiveMeu}`}>
        <Link to={"/profile"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-7 w-7"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clipRule="evenodd"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default BottomNavbar;
