// lib
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Swal from "sweetalert2";

// local
import HeaderSubMenu from "../components/HeaderSubMenu";
import KailokaContext from "../context/KailokaProvider";
import { GetCookies } from "../cookies/Cookies";

const Kailoka = () => {
  // useNavigate
  const navigate = useNavigate();
  // Context
  const {
    loading,
    connectLink,
    disconnectink,
    checkActiveLink,
    activeLink,
    loadingActive,
  } = useContext(KailokaContext);
  // useRef
  const codeRef = useRef();

  // useState
  const [code, setCode] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      activeLink.hasOwnProperty("id_production") &&
      activeLink.hasOwnProperty("u_production")
    ) {
      // Confirmation
      const confirm = await Swal.fire({
        position: "center",
        showCancelButton: true,
        title: "Disconnect",
        text: "Will reset your data",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          setCode("");
          return true;
        } else {
          return false;
        }
      });
      if (confirm) {
        await disconnectink();
      }
    } else {
      await connectLink({ u_production: code });
    }
  };
  useEffect(() => {
    if (GetCookies("user") === undefined) {
      navigate("/auth");
    }
  }, []);
  useEffect(() => {
    checkActiveLink();
  }, []);
  return (
    <>
      <HeaderSubMenu name={"Kailoka"} />
      {loadingActive ? (
        <Skeleton />
      ) : (
        <div className="flex flex-col gap-y-5 mt-10">
          <h1 className="ml-6 text-xl font-semibold">
            {activeLink.hasOwnProperty("id_production") &&
            activeLink.hasOwnProperty("u_production")
              ? "Click Button For Disconnect"
              : "Input your code"}
          </h1>
          <form onSubmit={handleSubmit} className="mx-6">
            {/* Code */}
            {activeLink.hasOwnProperty("id_production") &&
            activeLink.hasOwnProperty("u_production") ? null : (
              <div className="flex flex-col gap-y-1">
                <label htmlFor="code">Code</label>
                <input
                  type="text"
                  id="code"
                  placeholder="Input your code"
                  className="px-5 py-2 w-full border rounded-lg"
                  autoComplete="off"
                  onChange={(e) => setCode(e.target.value)}
                  value={code}
                  required
                  ref={codeRef}
                />
              </div>
            )}

            <br />
            <button
              className={`h-12 rounded-xl text-white block w-full ${
                loading ? "bg-red-300" : "bg-red-500"
              }`}
              type="submit"
              disabled={loading ? true : false}
            >
              {loading ? (
                <FontAwesomeIcon
                  icon={solid("spinner")}
                  className="animate-spin text-white h-8 w-8"
                />
              ) : activeLink.hasOwnProperty("id_production") &&
                activeLink.hasOwnProperty("u_production") ? (
                "Disconnect Account"
              ) : (
                "Connect Account"
              )}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default Kailoka;
