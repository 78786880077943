// lib
import { createContext, useState } from "react";
import Swal from "sweetalert2";

// local
import axios from "../api/axios";
import { GetCookies } from '../cookies/Cookies.js';

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const [profile, setProfile] = useState({});
  const [profilePage, setProfilePage] = useState({});


  const getProfile = async () => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;

    try {
      setLoading(true);
      const { data } = await axios.get(`/edit/profile/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setProfile(data.data)
      return true
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setLoading(false);
    }
  }

  const updateProfile = async (data) => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;
    try {
      setLoadingEdit(true);
      const res = await axios.post(`/update/profile/${uuid}`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (res.data) {
        Swal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          title: "Success Update Profile",
          text: false,
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
        });
      }
      return true;
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        title: "Failed Update Profile",
        text: err.response.data.message,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      return false;
    } finally {
      setLoadingEdit(false);
    }
  }

  const getProfilePage = async () => {
    const token = JSON.parse(GetCookies('user')).token;
    const uuid = JSON.parse(GetCookies('user')).uuid;

    try {
      setLoading(true);
      const { data } = await axios.get(`/profile/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setProfilePage(data.data)
      return true
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setLoading(false);
    }
  }
  return (
    <ProfileContext.Provider value={{ loading, getProfile, profile, updateProfile, loadingEdit, getProfilePage, profilePage }}>
      {children}
    </ProfileContext.Provider>
  )
}
export default ProfileContext;