// lib
import React, { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Swal from "sweetalert2";
// local
import HeaderSubMenu from "../components/HeaderSubMenu";
import ProfileContext from "../context/ProfileProvider";
import { GetCookies } from "../cookies/Cookies";
import kailoka from "../assets/images/logo/kailoka.png";
import background from "../assets/images/background.png";

const EditProfile = () => {
  // useNavigate
  const navigate = useNavigate();
  // useContext
  const { loading, getProfile, profile, updateProfile, loadingEdit } =
    useContext(ProfileContext);
  // useState
  const [file, setFile] = useState(null);
  const [imageBg, setImageBg] = useState(null);
  const [showBtn, setShowBtn] = useState(false);

  // useRef
  const nameRef = useRef();
  const usernameRef = useRef();
  const phoneRef = useRef();
  const bioRef = useRef();

  const showBtnSave = () => {
    if (nameRef.current.value !== profile.name) {
      setShowBtn(true);
    }
    if (usernameRef.current.value !== profile.username) {
      setShowBtn(true);
    }
    if (phoneRef.current.value !== profile.phone) {
      setShowBtn(true);
    }
    if (bioRef.current.value !== profile.bio) {
      setShowBtn(true);
    }
    if (
      nameRef.current.value === profile.name &&
      usernameRef.current.value === profile.username &&
      phoneRef.current.value === profile.phone &&
      bioRef.current.value === profile.bio
    ) {
      setShowBtn(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (file != null) {
      formData.append("image", file);
    }
    if (imageBg != null) {
      formData.append("image_background", imageBg);
    }
    formData.append("name", nameRef.current.value);
    formData.append("uname", usernameRef.current.value);
    formData.append("phone", phoneRef.current.value);
    formData.append("bio", bioRef.current.value);

    await updateProfile(formData);
    await getProfile();
    setShowBtn(false);
  };

  // useEffect
  useEffect(() => {
    if (GetCookies("user") === undefined) {
      navigate("/auth");
    }
  }, [navigate]);

  useEffect(() => {
    getProfile();
  }, []);
  return (
    <>
      <HeaderSubMenu name={"Edit Profile"} />
      <div className="relative mt-5">
        {loading ? (
          <Skeleton height={224} />
        ) : (
          <img
            src={
              profile.image_background == "" ||
              profile.image_background == null ||
              profile.image_background === "" ||
              profile.image_background === null
                ? background
                : `https://official.id/${profile.image_background}`
            }
            alt="backgroundProfile"
            className="w-full h-56 object-cover"
          />
        )}

        <label
          htmlFor="image_background"
          className=" absolute bottom-3 right-3"
        >
          <FontAwesomeIcon
            icon={solid("camera")}
            className="h-5 w-5 text-white p-1.5 rounded-full bg-red-500"
          />
        </label>
        <input
          className="hidden"
          type="file"
          id="image_background"
          placeholder="image here"
          onChange={(e) => {
            setShowBtn(true);
            Swal.fire({
              toast: true,
              position: "top",
              showConfirmButton: false,
              title: "Image Change, Please Save",
              text: false,
              icon: "success",
              timer: 1000,
              timerProgressBar: true,
            });
            setImageBg(e.target.files[0]);
          }}
        />
        {loading ? (
          <div className="absolute -bottom-12 left-0 right-0 flex justify-center">
            <Skeleton
              height={96}
              width={96}
              circle={true}
              className="border-4 border-white"
            />
          </div>
        ) : (
          <img
            src={
              profile.image == "" ||
              profile.image == null ||
              profile.image === "" ||
              profile.image === null
                ? kailoka
                : `https://official.id/${profile.image}`
            }
            alt="profileImage"
            className="rounded-full w-24 h-24 object-cover absolute -bottom-12 left-0 right-0 mx-auto  border-4 border-white bg-black"
          />
        )}

        {loading ? (
          <div className="absolute -bottom-20 left-0 right-0 mx-auto w-48">
            <Skeleton width={192} height={20} />
          </div>
        ) : (
          <>
            <label
              htmlFor="image"
              className="absolute -bottom-20 left-0 right-0 mx-auto w-48 text-center text-red-500"
            >
              Change Profile Photo
            </label>
            <input
              className="hidden"
              type="file"
              id="image"
              placeholder="image here"
              onChange={(e) => {
                setShowBtn(true);
                Swal.fire({
                  toast: true,
                  position: "top",
                  showConfirmButton: false,
                  title: "Image Change, Please Save",
                  text: false,
                  icon: "success",
                  timer: 1000,
                  timerProgressBar: true,
                });
                setFile(e.target.files[0]);
              }}
            />
          </>
        )}
      </div>
      {/* Form */}
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="mt-20 gap-y-5 flex flex-col mx-6 relative "
      >
        {/* Email */}
        {loading ? (
          <Skeleton height={40} className="mt-5" />
        ) : (
          <div className="flex flex-col gap-y-1">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your name"
              className="px-5 py-2 w-full border rounded-lg"
              defaultValue={profile.name != null ? profile.name : ""}
              required
              ref={nameRef}
              onChange={() => showBtnSave()}
            />
          </div>
        )}

        {/* Username */}
        {loading ? (
          <Skeleton height={40} className="mt-5" />
        ) : (
          <div className="flex flex-col gap-y-1">
            <label htmlFor="username">Username</label>
            <div className="flex items-center">
              <p className="text-gray-400">official.id/</p>
              <input
                type="text"
                id="username"
                name="username"
                className="pr-5 pl-1 py-2 w-full border rounded-lg"
                placeholder="Enter your username"
                defaultValue={profile.username != null ? profile.username : ""}
                ref={usernameRef}
                required
                onChange={() => showBtnSave()}
              />
            </div>
          </div>
        )}
        {/* Phone Number */}
        {loading ? (
          <Skeleton height={40} className="mt-5" />
        ) : (
          <div className="flex flex-col gap-y-1">
            <label htmlFor="phone">Phone Number</label>
            <div className="flex items-center">
              <p className="mr-2 ">+62</p>
              <input
                type="number"
                id="phone"
                name="phone"
                className="px-5 py-2 w-full border rounded-lg"
                placeholder="82123456789"
                defaultValue={profile.phone != null ? profile.phone : ""}
                required
                ref={phoneRef}
                onChange={() => showBtnSave()}
              />
            </div>
          </div>
        )}

        {/* Bio */}
        {loading ? (
          <Skeleton height={80} className="mt-5" />
        ) : (
          <div className="flex flex-col gap-y-1">
            <label htmlFor="bio">Bio</label>
            <div className="flex items-center">
              <textarea
                maxLength={120}
                id="bio"
                name="bio"
                rows="4"
                placeholder="Enter your bio"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 "
                defaultValue={profile.bio != null ? profile.bio : ""}
                required
                ref={bioRef}
                onChange={() => showBtnSave()}
              ></textarea>
            </div>
          </div>
        )}

        {/* Popup */}

        {showBtn ? (
          loading ? (
            <Skeleton height={40} className="mt-5" />
          ) : (
            // Button Save
            <button
              className={`h-12 rounded-xl text-white block w-full absolute -bottom-20 ${
                loadingEdit ? "bg-red-300" : "bg-red-500"
              }`}
              type="submit"
              disabled={loadingEdit ? true : false}
            >
              {loadingEdit ? (
                <FontAwesomeIcon
                  icon={solid("spinner")}
                  className="animate-spin text-white h-8 w-8"
                />
              ) : (
                "Save Changes"
              )}
            </button>
          )
        ) : null}
      </form>
    </>
  );
};

export default EditProfile;
