import React from "react";
import { useNavigate } from "react-router-dom";

const HeaderSubMenu = ({ name }) => {
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-6 mt-2">
      <button
        onClick={() => navigate(-1)}
        className="m-auto text-3xl font-medium"
      >
        {"<"}
      </button>
      <h1 className="col-span-5 my-auto text-xl font-semibold">{name}</h1>
    </div>
  );
};

export default HeaderSubMenu;
